import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { ThemeProvider } from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import { calculateFee, toFixedDown } from './helpers/fee';
import { moneyMapping, langMapping } from './constants/countryMap';
import { EXCHANGES } from './constants/constants';

import { useQuoteState } from './context/QuoteContext';
import { useConfigState, useConfigDispatch, getConfig } from './context/ConfigContext';
import { LimitProvider } from './context/LimitsContext';

import SellForm from './components/SellForm/SellForm';
import BuyForm from './components/BuyForm/BuyForm';
import CustomButton from './components/commons/CustomButton/CustomButton';

import './App.scss';

const STABLEX_LIST = ['ARST', 'BRLT', 'MXNT'];
const STABLEX_LIST_MAPPER = {
  AR: ['ARST'],
  BR: ['BRLT'],
  MX: ['MXNT']
};

const filterCryptoList = (_type, country, { enabledCrypto = [] } = {}) => {
  const filtered = enabledCrypto.filter(({ crypto, type }) => {
    const isSell = type.includes(_type.toLowerCase());
    const isStablexCrypto = STABLEX_LIST.includes(crypto);
    return (
      isSell && (!isStablexCrypto || !STABLEX_LIST_MAPPER[country] || STABLEX_LIST_MAPPER[country].includes(crypto))
    );
  });

  return filtered;
};

export default () => {
  const { t, i18n } = useTranslation();
  const localParams = new URLSearchParams(window.location.search);
  let typeParam = localParams.get('type');
  typeParam = typeParam === 'sell' ? 'Sell' : 'Buy';
  const [predefinedCrypto, setPredefinedCrypto] = useState(localParams.get('crypto') || undefined);
  const countryParam = localParams.get('country');
  const [crypto, setCrypto] = useState();
  const [fiat, setFiat] = useState();

  const [cryptoValue, setCryptoValue] = useState();
  const [fiatValue, setFiatValue] = useState('0');
  const [checkoutAmount, setCheckoutAmount] = useState('');
  const [consumerUserEmail, _setConsumerUserEmail] = useState('');
  const [otherExchange, setOtherExchange] = useState(null);
  const [country, setCountry] = useState('');
  const [feeAmount, setFeeAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [decimalScale, setDecimalScale] = useState();
  const [type, setType] = useState(typeParam);
  const [hashError, setHashError] = useState(false);
  const [exchange, setExchange] = useState(false);
  const defaultExchange = EXCHANGES.BINANCE;

  const handleFeeAmountChange = (_quoteInfo, _checkoutAmount, _feeAmount) => {
    _feeAmount = _feeAmount || calculateFee(_checkoutAmount, _quoteInfo);
    setFeeAmount(_feeAmount);
    setAmount(_checkoutAmount - _feeAmount);
  };

  const calcCryptoValue = _checkoutAmount => {
    const _feeAmount = calculateFee(_checkoutAmount.floatValue, quoteInfo);
    setCheckoutAmount(_checkoutAmount.formattedValue);
    setCryptoValue(
      _checkoutAmount.floatValue > 0
        ? toFixedDown((_checkoutAmount.floatValue - _feeAmount) / quoteInfo.quote, decimalScale)
        : 0
    );
    handleFeeAmountChange(quoteInfo, _checkoutAmount.floatValue, _feeAmount);
  };

  const calcFiatValue = _checkoutAmount => {
    const _feeAmount = calculateFee(_checkoutAmount.floatValue, quoteInfo);
    setCryptoValue(_checkoutAmount.formattedValue);
    setFiatValue(
      _checkoutAmount.floatValue > 0 && _checkoutAmount.floatValue - _feeAmount > 0
        ? toFixedDown((_checkoutAmount.floatValue - _feeAmount) * quoteInfo.quote, decimalScale)
        : 0
    );
    handleFeeAmountChange(quoteInfo, _checkoutAmount.floatValue, _feeAmount);
  };

  const handleChangeCrypto = _crypto => {
    setCrypto(_crypto);
    setDecimalScale(widgetConfig.enabledCrypto.find(c => c.crypto === _crypto).decimal);
    setCheckoutAmount(undefined);
    setCryptoValue(undefined);
    setFiatValue(0);
  };

  const handleChangeExchange = _exchange => {
    setExchange(_exchange);
    setCheckoutAmount(undefined);
    setCryptoValue(undefined);
    setFiatValue(0);
  };

  const handleChangeFiat = _country => {
    if (_country === country) return;

    window.parent.postMessage(
      {
        event: 'countryChanged',
        country: _country
      },
      '*'
    );
    setCountry(_country);
    setFiat(_country);

    setCrypto(undefined);
    setDecimalScale(undefined);
    setCheckoutAmount(undefined);
    setCryptoValue(undefined);
    setFiatValue(0);

    setCheckoutAmount(undefined);
    calcCryptoValue(0);
    i18n.changeLanguage(langMapping[_country]);

    setFiatValue(0);
  };

  const changeType = type => {
    setType(type);
    setCheckoutAmount(undefined);
    calcCryptoValue(0);
    setFiatValue(0);
    _setConsumerUserEmail('')
    setOtherExchange('')
    setExchange(defaultExchange)
    const selectedCrypto = filterCryptoList(type, country, widgetConfig)[0];
    setCrypto(selectedCrypto.crypto);
    setDecimalScale(selectedCrypto.decimal);
  };

  const getIcon = (type, name) => {
    let icon;
    try {
      icon = require(`./images/${type}/${name.toLowerCase()}.svg`);
    } catch (error) {
      icon = require(`./images/${type}/default.svg`);
    }
    return icon;
  };

  const setConsumerUserEmail = email => {
    _setConsumerUserEmail(email ? email.toLowerCase() : '');
  };

  const handleOtherExchange = exchange => setOtherExchange(exchange ? exchange : null)

  const { quoteInfo, loading } = useQuoteState();
  const { widgetConfig } = useConfigState();
  const configDispatch = useConfigDispatch();

  useEffect(() => {
    if (!configDispatch) return;

    const hash = localParams.get('hash');
    const email = localParams.get('email');

    setConsumerUserEmail(email);

    if (!hash) setHashError(true);
    getConfig(configDispatch, hash);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configDispatch]);

  useEffect(() => {
    if (fiat || (!predefinedCrypto && crypto) || !widgetConfig.enabledCrypto || !widgetConfig.enabledCrypto[0] || !type)
      return;
    setFiat(countryParam || widgetConfig.enabledCountries[0]);
    setCountry(countryParam || widgetConfig.enabledCountries[0]);
    i18n.changeLanguage(langMapping[countryParam || widgetConfig.enabledCountries[0]]);
    let selectedCrypto;
    // The setFiat call above, is triggering another useEffect where the crypto is changed again and it generates a double call to get the quote.
    // To avoid this, i'm setting the crypto here and there to the predefined one otherwise there's a race condition and the quote that is shown is wrong half of the time.
    if (predefinedCrypto) {
      selectedCrypto = widgetConfig.enabledCrypto.filter(({ crypto: c }) => c === predefinedCrypto)[0];
    } else {
      selectedCrypto = widgetConfig.enabledCrypto.filter(({ type: t }) => t.includes(type.toLowerCase()))[0];
    }

    console.log('Setting crypto', selectedCrypto);
    setCrypto(selectedCrypto.crypto);
    setDecimalScale(selectedCrypto.decimal);
    setExchange(defaultExchange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetConfig, fiat, crypto, type]);

  useEffect(() => {
    if (countryParam) {
      i18n.changeLanguage(langMapping[countryParam]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryParam]);

  if (hashError || (!widgetConfig.enabledCountries && !countryParam) || !widgetConfig.enabledCrypto) {
    return <Skeleton height={400} />;
  }

  const countriesArray = countryParam ? [countryParam] : widgetConfig.enabledCountries;

  const fiatOptions = countriesArray.map(country => ({
    value: country,
    name: moneyMapping[country],
    icon: getIcon('countries', country)
  }));

  const sellCryptoOptions = filterCryptoList('sell', country, widgetConfig).map(({ crypto, decimal }) => ({
    decimal,
    value: crypto,
    name: crypto,
    icon: getIcon('cryptos', crypto)
  }));

  const buyCryptoOptions = filterCryptoList('buy', country, widgetConfig).map(({ crypto, decimal }) => ({
    decimal,
    value: crypto,
    name: crypto,
    icon: getIcon('cryptos', crypto)
  }));

  const cryptoList = type === 'Buy' ? buyCryptoOptions : sellCryptoOptions;

  const controlled = localParams.get('controlled');

  useEffect(() => {
    if (fiat) {
      // We need to reset the crypto when the FIAT changes, but the first time we need to check if a predefined
      // crypto exists, and if so set that one.
      let selectedCrypto;
      if (predefinedCrypto) {
        selectedCrypto =
          cryptoList && cryptoList.length ? cryptoList.filter(({ value: v }) => v === predefinedCrypto)[0] : null;
        setPredefinedCrypto(undefined);
      } else {
        selectedCrypto = cryptoList && cryptoList.length > 0 ? cryptoList[0] : null;
      }

      setCrypto(selectedCrypto ? selectedCrypto.value : null);
      setDecimalScale(selectedCrypto ? selectedCrypto.decimal : null);
      setCheckoutAmount(undefined);
      setCryptoValue(undefined);
      setFiatValue(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiat]);

  const theme = {
    white: '#fff',
    'mercury-gray': '#e6e6e6',
    'boulder-gray': '#797979',
    'mine-shaft-gray': '#2c2c2c',
    'main-color': widgetConfig.mainColor || '#fff',
    'silver-chalice-gray': '#a6a6a6'
  };

  const providerPSP = country && widgetConfig.providerPSP ? widgetConfig.providerPSP[country] : '';
  const orderTypes = widgetConfig.orderTypes || { Deposit: undefined, Withdraw: undefined };
  const merchant = widgetConfig.merchant;

  return (
    <ThemeProvider theme={theme}>
      <Grid fluid>
        {widgetConfig.enabledCrypto.length > 0 ? (
          <Row className="button-container">
            <Col xs={6} sm={2}>
              <CustomButton tab active={type === 'Buy'} onClick={() => changeType('Buy')}>
                {t('checkoutForm.buyButton')}
              </CustomButton>
            </Col>
            <Col xs={6} sm={2}>
              <CustomButton tab active={type === 'Sell'} onClick={() => changeType('Sell')}>
                {t('checkoutForm.sellButton')}
              </CustomButton>
            </Col>
          </Row>
        ) : (
          <Skeleton height={99} />
        )}
        <LimitProvider>
          <div style={{ position: 'relative' }}>
            <div className={`form-container ${type === 'Buy' ? 'active-form' : ''}`}>
              {
                <BuyForm
                  {...{
                    checkoutAmount,
                    fiatOptions,
                    fiat,
                    cryptoOptions: buyCryptoOptions,
                    crypto,
                    cryptoValue,
                    country,
                    feeAmount,
                    decimalScale,
                    amount,
                    type,
                    quoteInfo,
                    loading,
                    consumerUserEmail,
                    setConsumerUserEmail,
                    controlled,
                    exchange,
                    otherExchange,
                    handleOtherExchange,
                  }}
                  globalTerms={widgetConfig.globalTerms}
                  pid={widgetConfig.pid}
                  providerPSP={providerPSP}
                  orderType={orderTypes.Deposit}
                  merchant={merchant}
                  consumer={widgetConfig.consumer || 'latamex'}
                  callbackUrl={widgetConfig.callbackUrl}
                  onChangeFiat={handleChangeFiat}
                  onChangeCrypto={handleChangeCrypto}
                  onAmountChange={calcCryptoValue}
                  onChangeExchange={handleChangeExchange}
                />
              }
            </div>
            <div className={`form-container ${type === 'Sell' ? 'active-form' : ''}`}>
              {
                <BuyForm
                  {...{
                    checkoutAmount,
                    fiatOptions,
                    fiat,
                    cryptoOptions: buyCryptoOptions,
                    crypto,
                    cryptoValue,
                    country,
                    feeAmount,
                    decimalScale,
                    amount,
                    type,
                    quoteInfo,
                    loading,
                    consumerUserEmail,
                    setConsumerUserEmail,
                    controlled,
                    exchange,
                    otherExchange,
                    handleOtherExchange,
                  }}
                  globalTerms={widgetConfig.globalTerms}
                  pid={widgetConfig.pid}
                  providerPSP={providerPSP}
                  orderType={orderTypes.Deposit}
                  merchant={merchant}
                  consumer={widgetConfig.consumer || 'latamex'}
                  callbackUrl={widgetConfig.callbackUrl}
                  onChangeFiat={handleChangeFiat}
                  onChangeCrypto={handleChangeCrypto}
                  onAmountChange={calcCryptoValue}
                  onChangeExchange={handleChangeExchange}
                />
              }
            </div>
          </div>
        </LimitProvider>
      </Grid>
    </ThemeProvider>
  );
};
