export const calculateFee = (amount, quote) => {
  const fee = (quote.fee * amount) / 100;
  return quote.flatFee + Math.max(quote.minFee, fee);
};

export const toFixedDown = (value, digits) => {
  const re = new RegExp(`(\\d+\\.\\d{${digits}})(\\d)`);

  const m = value.toString().match(re);
  return m ? parseFloat(m[1]) : value.valueOf();
};
