import React, { useState, useEffect, useRef } from 'react';
import { SelectorIcon } from '@heroicons/react/solid';


import { CustomSelectStyled } from './CustomSelectStyle';

const CustomSelect = ({ options = [], onSelect, selected, fiatSelect = false, ...props }) => {
  const [opened, setOpened] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpened(false);
    }
  };

  const handleSelect = selected => {
    onSelect(selected);
    setOpened(false);
  };

  const currentSelection = options.find(option => option.value === selected);

  return (
    <CustomSelectStyled ref={wrapperRef} {...props}>
      <div className="selected" onClick={() => setOpened(!!!opened)}>
        <span>
          <span>
            {currentSelection && currentSelection.icon && (
              <img alt={currentSelection.name} src={currentSelection.icon} />
            )}
            <span>{currentSelection ? currentSelection.name : ''}</span>
          </span>
          <span className="arrow-container">
            {
              <SelectorIcon className="arrow" />
            }
          </span>
        </span>
      </div>
      <div className={`selectorContainer ${opened ? 'opened' : ''}`}>
        <ul>
          {options.map((option) => (
            <li key={option.value} onClick={() => handleSelect(option.value)}>
              {option.icon && <img alt={option.name} src={option.icon} />}
              {option.name}
            </li>
          ))}
        </ul>
      </div>
    </CustomSelectStyled>
  );
};

export default CustomSelect;
