import _ from 'lodash';

export const post = (path, params, method = 'post') => {
  const form = document.createElement('form');
  form.method = method;
  form.action = path;
  form.target = '_parent';

  _.each(params, (value, key) => {
    if (value === undefined) return;
    
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = key;
    hiddenField.value = value;
    form.appendChild(hiddenField);
  });

  document.body.appendChild(form);
  form.submit();
};

export const createNewCheckout = bodyPost => {
  post(`${process.env.REACT_APP_API_URL}/checkout/new`, bodyPost);
};

export const createControlledCheckout = bodyPost => {
  window.parent.postMessage(
    {
      event: 'newCheckout',
      checkoutData: bodyPost
    },
    '*'
  );
};
