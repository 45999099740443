import React from 'react';
import { Api } from '../actions/Api';

const ConfigStateContext = React.createContext();
const ConfigDispatchContext = React.createContext();

function configReducer(state, action) {
  switch (action.type) {
    case 'START_UPDATE': {
      return { ...state, loading: true, errorLoadingConfig: false };
    }
    case 'CONFIG_UPDATE': {
      return { ...state, widgetConfig: action.data, errorLoadingConfig: false, loading: false };
    }
    case 'FAIL_UPDATE': {
      return { ...state, widgetConfig: {}, errorLoadingConfig: true, loading: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ConfigProvider({ children }) {
  const [state, dispatch] = React.useReducer(configReducer, {
    widgetConfig: { enabledCrypto: [], enabledCountries: [] },
    errorLoadingConfig: false
  });
  return (
    <ConfigStateContext.Provider value={state}>
      <ConfigDispatchContext.Provider value={dispatch}>{children}</ConfigDispatchContext.Provider>
    </ConfigStateContext.Provider>
  );
}

async function getConfig(dispatch, hash) {
  dispatch({ type: 'START_UPDATE' });
  try {
    const { data } = await Api.getWidgetConfig(hash);
    dispatch({ type: 'CONFIG_UPDATE', data });
  } catch (error) {
    dispatch({ type: 'FAIL_UPDATE', error });
  }
}

function useConfigState() {
  const context = React.useContext(ConfigStateContext);
  if (context === undefined) {
    throw new Error('useConfigState must be used within a ConfigProvider');
  }
  return context;
}

function useConfigDispatch() {
  const context = React.useContext(ConfigDispatchContext);
  if (context === undefined) {
    throw new Error('useConfigDispatch must be used within a ConfigProvider');
  }
  return context;
}
export { ConfigProvider, useConfigState, useConfigDispatch, getConfig };
