import React from 'react';

import { Button } from './CustomButtonStyles';

const CustomButton = ({
  onClick,
  children,
  outlined = false,
  active = false,
  rounded = false,
  solid = false,
  tab = false,
  ...props
}) => (
  <Button
    className={`custom-button ${outlined ? 'outlined ' : ''}${active ? 'active ' : ''}${rounded ? 'rounded ' : ''}${
      solid ? 'solid ' : ''} ${tab ? 'tab' : ''}`}
    onClick={onClick}
    {...props}
  >
    {props.disabled ? <span className="loadingSpinner"></span> : <>{children}</>}
  </Button>
);

export default CustomButton;
