import apisauce from 'apisauce';

const addQueryParams = (endpoint, params) =>
  Object.keys(params)
    .reduce((ant, paramKey) => (params[paramKey] ? `${ant}${paramKey}=${params[paramKey]}&` : ant), `${endpoint}?`)
    .slice(0, -1);

const create = (baseURL = '') => {
  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache'
    },
    timeout: 1000000
  });

  const naviMonitor = response => console.log('API MONITOR ACTIVITY:', response);
  api.addMonitor(naviMonitor);

  const getQuote = (fromAsset, toAsset, type, pid) =>
    api.get(addQueryParams('/quotes', { fromAsset, toAsset, type, pid }));

  const getWidgetConfig = hash => api.get(addQueryParams('/checkout/widget', { hash }));

  const getLimits = country => api.get(addQueryParams(`/limit/${country}`, {}));

  const createCheckout = bodyPost => api.post('/checkout/new', { ...bodyPost });

  return {
    getLimits,
    getQuote,
    getWidgetConfig,
    createCheckout
  };
};

export default {
  create
};

export const Api = create(process.env.REACT_APP_API_URL);
