import styled from 'styled-components';

export const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${(props) => props.theme['main-color']};
  font-size: 1rem;
  font-weight: 600;
  border: 0;
  background: none;
  height: 45px;
  width: 100%;
  display: block;
  cursor: pointer;
  margin: 0.5625rem 0;

  &:focus {
    outline: 0;
    outline-offset: -4px;
  }

  &:active {
    transform: scale(0.99);
  }

  &.outlined {
    border: 1px solid ${(props) => props.theme['silver-chalice-gray']};
    color: ${(props) => props.theme['silver-chalice-gray']};
    &.active {
      border: 1px solid ${(props) => props.theme['main-color']};
      color: ${(props) => props.theme['main-color']};
    }
  }
  &.rounded {
    border-radius: 27px;
  }
  &.solid {
    background: ${(props) => props.theme['main-color']};
    border-radius: 6px;
    color: ${(props) => props.theme['white']};
    &:disabled {
      opacity: 0.4;
    }
  }
  &.tab {
    color: ${(props) => props.theme['silver-chalice-gray']};
    border-bottom: 2px solid ${(props) => props.theme['silver-chalice-gray']};
    &.active {
      color: ${(props) => props.theme['main-color']};
      border-color: ${(props) => props.theme['main-color']};
    }
  }
`;
