export default {
  'checkoutForm.buyButton': 'A ver',
  'checkoutForm.sellButton': 'Venta',
  'checkoutForm.checkWalletAddress': 'Verifique o endereço da sua carteira',
  'checkoutForm.doNotHaveWallet': 'Não tens carteira?',
  'checkoutForm.enterHigherValue': 'O valor mínimo de compra é {{fiatAsset}} {{minAmount}}',
  'checkoutForm.enterLowerValue': 'Entrar um valor inferior',
  'checkoutForm.enterValidEmail': 'O e-mail não é válido',
  'checkoutForm.enterYourAmount': 'Digite o valor',
  'checkoutForm.enterYourEmail': 'Digite seu e-mail',
  'checkoutForm.enterYourWallet': 'Digite sua carteira',
  'checkoutForm.includedFees': 'Taxas incluídas',
  'checkoutForm.minAmountError': 'O valor mínimo de compra é {{fiatAsset}} {{minAmount}}',
  'checkoutForm.minAmountError.withdraw': 'O valor mínimo de venta é {{cryptoAsset}} {{minAmount}}',
  'checkoutForm.mustEnterValue': 'É necessário entrar algum valor',
  'checkoutForm.termsCondDisclaimer':
    'O preço inclui as despesas do processador de pagamento desde {{fee}}%{{minFeeString}}{{flatFeeString}}. Continuando, você aceita os <1>termos e condições</1>.',
  'checkoutForm.flatFee': ' + taxa fixa {{fiatAsset}} {{flatFee}}',
  'checkoutForm.tooltip':
    'Preço com base na cotação atual. A <br/>cotação final pode variar assim que seu <br/>pagamento for creditado. Depois que seu <br/>pagamento for recebido, um e-mail será <br/>enviado a você para confirmar a <br/>transação com a taxa final.',
  'checkoutForm.wantToBuy': 'Quero comprar',
  'checkoutForm.wantToSpend': 'A quantidade de',
  'checkoutForm.wantToSell': 'Quero vender',
  'checkoutForm.wantToReceive': 'Quero receber',
  'checkoutForm.exchangeToReceive': 'Recebe na wallet',
  'checkoutForm.myEmail': 'Meu email',
  'checkoutForm.weNeedYourEmail': 'Precisamos do seu e-mail para o pedido',
  'checkoutForm.getStarted': 'Começar',
  'checkoutForm.whatIsYourWallet': 'Qual é a sua outra wallet?',
  'checkoutForm.maxCharacters': 'O número máximo de caracteres é 50',
  'checkoutForm.alphaNumeric': 'Somente caracteres alfa numéricos são permitidos',
};
