export default {
  'checkoutForm.buyButton': '',
  'checkoutForm.checkWalletAddress': '',
  'checkoutForm.doNotHaveWallet': '',
  'checkoutForm.enterHigherValue': '',
  'checkoutForm.enterLowerValue': '',
  'checkoutForm.enterValidEmail': '',
  'checkoutForm.enterYourAmount': '',
  'checkoutForm.enterYourEmail': '',
  'checkoutForm.enterYourWallet': '',
  'checkoutForm.includedFees': '',
  'checkoutForm.minAmountError': '',
  'checkoutForm.mustEnterValue': '',
  'checkoutForm.termsCondDisclaimer':
    'Price does not include payment processor fees from {{fee}}%{{minFeeString}}{{flatFeeString}}. By continuing you are accepting the <1>terms and conditions</1>.',
  'checkoutForm.flatFee': ' + flat fee {{fiatAsset}} {{flatFee}}',
  'checkoutForm.tooltip': '',
  'checkoutForm.wantToBuy': '',
  'checkoutForm.wantToSpend': '',
};
