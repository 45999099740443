import styled from 'styled-components';

export const CustomSelectStyled = styled.div`
  user-select: none;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  .selected {
    cursor: pointer;
    width: 100%;
    padding: 10px 0;
    span {
      font-size: 1rem;
      color: ${(props) => props.theme['mine-shaft-gray']};
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 24px;
        padding: 0 10px 0 0;
      }
    }
  }
  & > .selectorContainer {
    transition: all 0.4s;
    position: absolute;
    transform: translateY(0);
    opacity: 0;
    cursor: pointer;
    z-index: -1;
    visibility: hidden;
    &.opened {
      opacity: 1;
      transform: translateY(20px);
      z-index: 1;
      visibility: visible;
    }
    ul {
      border: solid 2px ${(props) => props.theme['mercury-gray']};
      background-color: ${(props) => props.theme['white']};
      position: absolute;
      margin: 0;
      list-style: none;
      min-width: 100px;
      padding: 0;
      max-height: 195px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }

      li {
        font-size: 1rem;
        color: ${(props) => props.theme['mine-shaft-gray']};
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0.5rem 0.625rem;
        &:hover {
          background-color: ${(props) => props.theme['mercury-gray']};
        }
        &:last-child {
          margin-bottom: 0;
        }
        img {
          width: 24px;
          padding: 0 10px 0 0;
        }
      }
    }
    @media (max-width: 480px) {
      width: 100%;
      left: 0;
      & > ul {
        width: 99%;
      }
    }
  }
  .arrow-container {
    position: relative;
    justify-content: flex-end !important;
    .arrow {
      width: 22px;
      height: 22px;
      display: inline-block;
      position: absolute;
      margin: 0 0 0 10px;
      right: 0px;
    }
  }
`;
