import IconRippio from "../images/exchanges/Ripio.svg"
import IconBinance from "../images/exchanges/Binance.svg";
import IconOkex from "../images/exchanges/Okex.svg";
import IconBitso from "../images/exchanges/Bitso.svg";
import IconBitStamp from "../images/exchanges/Bitstamp.svg";
import IconFtx from "../images/exchanges/FTX.svg";
import IconKraken from "../images/exchanges/Kraken.svg";
import IconOther from "../images/exchanges/Other.svg";

const exchanges = [
  {
    value: "Binance",
    name: "Binance",
    icon: IconBinance,
  },
  {
    value: "Okex",
    name: "Okex",
    icon: IconOkex,
  },
  {
    value: "Rippio",
    name: "Rippio",
    icon: IconRippio,
  },
  {
    value: "Bitso",
    name: "Bitso",
    icon: IconBitso,
  },
  {
    value: "Bitstamp",
    name: "Bitstamp",
    icon: IconBitStamp,
  },
  {
    value: "FTX",
    name: "FTX",
    icon: IconFtx,
  },
  {
    value: "Kraken",
    name: "Kraken",
    icon: IconKraken,
  },
  {
    value: "Otra",
    name: "Otra",
    icon: IconOther,
  },
];

export default exchanges;
