import React from 'react';
import { Api } from '../actions/Api';

const LimitStateContext = React.createContext();
const LimitDispatchContext = React.createContext();

function limitReducer(state, action) {
  switch (action.type) {
    case 'START_UPDATE': {
      return { ...state, limitInfo: {}, loading: true };
    }
    case 'LIMITS_UPDATE': {
      return { ...state, limitInfo: action.data, loading: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function LimitProvider({ children }) {
  const [state, dispatch] = React.useReducer(limitReducer, {
    limitInfo: {}
  });
  return (
    <LimitStateContext.Provider value={state}>
      <LimitDispatchContext.Provider value={dispatch}>{children}</LimitDispatchContext.Provider>
    </LimitStateContext.Provider>
  );
}

async function updateLimit(dispatch, { country }) {
  dispatch({ type: 'START_UPDATE' });
  try {
    const { data } = await Api.getLimits(country);
    dispatch({ type: 'LIMITS_UPDATE', data });
  } catch (error) {
    dispatch({ type: 'FAIL_UPDATE', error });
  }
}

function useLimitState() {
  const context = React.useContext(LimitStateContext);
  if (context === undefined) {
    throw new Error('useLimitState must be used within a LimitProvider');
  }
  return context;
}

function useLimitDispatch() {
  const context = React.useContext(LimitDispatchContext);
  if (context === undefined) {
    throw new Error('useLimitDispatch must be used within a LimitProvider');
  }
  return context;
}
export { LimitProvider, useLimitState, useLimitDispatch, updateLimit };
