import React from 'react';
import { Api } from '../actions/Api';

const QuoteStateContext = React.createContext();
const QuoteDispatchContext = React.createContext();

function quoteReducer(state, action) {
  switch (action.type) {
    case 'START_UPDATE': {
      return { ...state, quoteInfo: {}, loading: true };
    }
    case 'QUOTE_UPDATE': {
      return { ...state, quoteInfo: action.data, loading: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function QuoteProvider({ children }) {
  const [state, dispatch] = React.useReducer(quoteReducer, {
    quoteInfo: {},
    cryptoAssets: {}
  });
  return (
    <QuoteStateContext.Provider value={state}>
      <QuoteDispatchContext.Provider value={dispatch}>{children}</QuoteDispatchContext.Provider>
    </QuoteStateContext.Provider>
  );
}

async function updateQuote(dispatch, { originAsset, destinationAsset, type, pid }) {
  dispatch({ type: 'START_UPDATE' });
  try {
    const { data } = await Api.getQuote(originAsset, destinationAsset, type, pid);
    dispatch({ type: 'QUOTE_UPDATE', data });
  } catch (error) {
    dispatch({ type: 'FAIL_UPDATE', error });
  }
}

function useQuoteState() {
  const context = React.useContext(QuoteStateContext);
  if (context === undefined) {
    throw new Error('useQuoteState must be used within a QuoteProvider');
  }
  return context;
}

function useQuoteDispatch() {
  const context = React.useContext(QuoteDispatchContext);
  if (context === undefined) {
    throw new Error('useQuoteDispatch must be used within a QuoteProvider');
  }
  return context;
}
export { QuoteProvider, useQuoteState, useQuoteDispatch, updateQuote };
