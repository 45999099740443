import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { useTranslation, Trans } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { Api } from '../../actions/Api';
import CurrencyFormat from 'react-currency-format';
import Skeleton from 'react-loading-skeleton';

import { createControlledCheckout } from '../../helpers/post';
import { isValidInput } from '../../helpers/validations';
import { EXCHANGES } from '../../constants/constants';

import { useLimitState, useLimitDispatch, updateLimit } from '../../context/LimitsContext';

import Outlined from '../commons/Outlined/Outlined';
import CustomSelect from '../commons/CustomSelect/CustomSelect';
import QuoteInfo from '../QuoteInfo/QuoteInfo';
import CustomButton from '../commons/CustomButton/CustomButton';
import { moneyMapping } from '../../constants/countryMap';
import exchanges from '../../assets/exchanges';


const BuyForm = ({
  checkoutAmount,
  fiatOptions,
  fiat,
  type = 'Buy',
  onChangeFiat,
  cryptoOptions,
  crypto,
  onChangeCrypto,
  amount,
  cryptoValue,
  onAmountChange,
  feeAmount,
  quoteInfo,
  loading,
  decimalScale,
  country,
  consumer,
  consumerUserEmail,
  setConsumerUserEmail,
  pid,
  providerPSP,
  orderType,
  merchant,
  globalTerms,
  callbackUrl,
  controlled,
  exchange,
  onChangeExchange,
  otherExchange,
  handleOtherExchange,
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, control, setValue, clearErrors } = useForm();
  const { limitInfo } = useLimitState();
  const limitsDispatch = useLimitDispatch();
  const [disabledForm, setDisabledForm] = useState(false);
  const otherSelected = exchange === EXCHANGES.OTHER;

  const createNewCheckout = async bodyPost => {
    try {
      const res = await Api.createCheckout(bodyPost);
      const url = res.data.redirectUrl.replace('https://checkout-staging2.latamex.mx', 'http://app.resuave.com');
      window.parent.location.href = url;
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (!limitsDispatch || !country) return;
    updateLimit(limitsDispatch, { country });
  }, [country, limitsDispatch]);

  useEffect(() => {
    if (checkoutAmount) return;
    setValue('checkoutAmount', checkoutAmount || '');
    clearErrors('checkoutAmount');
  }, [checkoutAmount, clearErrors, setValue]);

  const onSubmit = (data) => {
    setDisabledForm(true);
    const bodyPost = {
      type: 'Deposit',
      version: 1,
      transactionRef: undefined,
      consumer,
      destinationAsset: crypto,
      originAsset: moneyMapping[country],
      checkoutAmount: amount,
      cryptoAmount: cryptoValue,
      feeAmount,
      consumerUserEmail,
      consumerUserId: '-----',
      quote: quoteInfo.quote,
      countryCode: country,
      callbackUrl: callbackUrl,
      providerPSP: providerPSP || '',
      orderType,
      merchant,
      exchange,
      otherExchange,
      preventRedirect: true
    };
    if (controlled) {
      createControlledCheckout(bodyPost);
    } else {
      createNewCheckout(bodyPost);
    }
  };

  const handleExchange = (e) => {
    handleOtherExchange(e.target.value)
  }

  const getFloatAmount = (checkoutAmountInput) => {
    const plainValue =
      typeof checkoutAmountInput === 'string'
        ? parseFloat(checkoutAmountInput.replace(/,/g, ''))
        : parseFloat(checkoutAmountInput);
    return plainValue;
  };

  const { fee, minFee, flatFee, fiatMinAmount, fiatMaxAmount } = quoteInfo;
  const fiatAsset = moneyMapping[country];

  return (
    <>
      <Row>
        <Col xs={12} sm={6}>
          <span className="label-form">{t('checkoutForm.wantToBuy')}</span>
          <Outlined>
            <CustomSelect options={cryptoOptions} selected={crypto} onSelect={onChangeCrypto} />
          </Outlined>
        </Col>
        <Col xs={12} sm={6}>
          <span className="label-form">{t('checkoutForm.wantToSpend')}</span>
          <Outlined>
            <Row>
              <Col xs={8} sm={7}>
                <Controller
                  as={<CurrencyFormat />}
                  control={control}
                  rules={{
                    required: true,
                    validate: {
                      minAmount: (value) => getFloatAmount(value) >= fiatMinAmount,
                      maxAmount: (value) =>
                        getFloatAmount(value) <= limitInfo.hardLimit &&
                        (!fiatMaxAmount || getFloatAmount(value) <= fiatMaxAmount),
                    },
                  }}
                  disabled={loading}
                  defaultValue={checkoutAmount}
                  name="checkoutAmount"
                  onValueChange={(values) => onAmountChange(values)}
                  decimalScale={2}
                  fixedDecimalScale={false}
                  allowNegative={false}
                  thousandSeparator
                  maxLength={12}
                  placeholder={t('checkoutForm.enterYourAmount')}
                />
              </Col>
              <Col xs={4} sm={5}>
                <CustomSelect options={fiatOptions} selected={fiat} onSelect={onChangeFiat} fiatSelect />
              </Col>
            </Row>
          </Outlined>
          {errors.checkoutAmount && errors.checkoutAmount.type === 'required' && (
            <small className="error">{t('checkoutForm.mustEnterValue')}</small>
          )}
          {errors.checkoutAmount && errors.checkoutAmount.type === 'maxAmount' && (
            <small className="error">{t('checkoutForm.enterLowerValue')}</small>
          )}
          {errors.checkoutAmount && errors.checkoutAmount.type === 'minAmount' && (
            <small className="error">
              {t('checkoutForm.enterHigherValue', {
                fiatAsset: fiat,
                minAmount: fiatMinAmount,
              })}
            </small>
          )}
        </Col>
      </Row>

      {type === 'Buy' && <QuoteInfo {...{ cryptoValue, crypto, fiat, decimalScale, type, pid }} />}

      {!controlled && (
        <Row>
          {/* <Col xs={12} sm={6}>
            <span className="label-form">{t('checkoutForm.exchangeToReceive')}</span>
            <Outlined>
              <CustomSelect options={exchanges} selected={exchange} onSelect={onChangeExchange} />
            </Outlined>
          </Col> */}
          {otherSelected && (
            <Col xs={12} sm={6}>
              <span className="label-form">{t('checkoutForm.whatIsYourWallet')}</span>
              <Outlined>
                <input
                  ref={register({
                    required: true,
                    validate: {
                      maxCharacters: (value) => value.toString().length <= 50,
                      alphaNumeric: (value) => isValidInput(value),
                    },
                  })}
                  placeholder="Ej.: Buenbit"
                  type="text"
                  name="otherExchange"
                  onChange={handleExchange}
                />
              </Outlined>
              {errors.otherExchange && errors.otherExchange.type === 'required' && (
                <small className="error">{t('checkoutForm.mustEnterValue')}</small>
              )}
              {errors.otherExchange && errors.otherExchange.type === 'maxCharacters' && (
                <small className="error">{t('checkoutForm.maxCharacters')}</small>
              )}
              {errors.otherExchange && errors.otherExchange.type === 'alphaNumeric' && (
                <small className="error">{t('checkoutForm.alphaNumeric')}</small>
              )}
            </Col>
          )}
          <Col xs={12} sm={otherSelected ? 12 : 6}>
            <span className="label-form">{t('checkoutForm.myEmail')}</span>
            <Outlined>
              <input
                placeholder={t('checkoutForm.enterYourEmail')}
                type="text"
                ref={register({
                  required: true,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
                value={consumerUserEmail}
                name="consumerUserEmail"
                onChange={(e) => setConsumerUserEmail(e.target.value)}
              />
            </Outlined>
            {errors.consumerUserEmail && <small className="error">{t('checkoutForm.enterValidEmail')}</small>}
            <span className="label-email">{t('checkoutForm.weNeedYourEmail')}</span>
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={12} sm={12}>
          <CustomButton solid disabled={disabledForm} onClick={handleSubmit(onSubmit)}>
            {t('checkoutForm.getStarted')}
          </CustomButton>
        </Col>
      </Row>


        <Col xs={12} sm={7} className="terms-and-conds">
          <>
            {Object.keys(quoteInfo).length !== 0 ? (
              <Trans i18nKey="checkoutForm.termsCondDisclaimer">
                {{
                  fee,
                  minFeeString: minFee ? ` (min ${fiatAsset} ${minFee + (flatFee || 0)})` : '',
                  flatFeeString: flatFee ? t('checkoutForm.flatFee', { fiatAsset, flatFee }) : '',
                }}
                <a href={`${globalTerms[country]}/${country.toLowerCase()}`} target="_blank" rel="noopener noreferrer">
                  terms and conditions
                </a>
              </Trans>
            ) : (
              <Skeleton height={25} />
            )}
          </>
        </Col>

    </>
  );
};

export default BuyForm;
