export default {
  'checkoutForm.buyButton': 'Desde México',
  'checkoutForm.sellButton': 'Hacia México',
  'checkoutForm.checkWalletAddress': 'Checa la dirección de tu billetera',
  'checkoutForm.doNotHaveWallet': 'No tienes una billetera?',
  'checkoutForm.enterHigherValue': 'El valor mínimo de compra es {{fiatAsset}} {{minAmount}}',
  'checkoutForm.enterLowerValue': 'Ingresa un valor menor',
  'checkoutForm.enterValidEmail': 'El email no es válido',
  'checkoutForm.enterYourAmount': 'Ingresa el importe',
  'checkoutForm.enterYourEmail': 'Ingresa tu email',
  'checkoutForm.enterYourWallet': 'Ingresa tu billetera',
  'checkoutForm.includedFees': 'Fees incluídos',
  'checkoutForm.minAmountError': 'El valor mínimo de compra es {{fiatAsset}} {{minAmount}}',
  'checkoutForm.minAmountError.withdraw': 'El valor mínimo de venta es {{cryptoAsset}} {{minAmount}}',
  'checkoutForm.mustEnterValue': 'Debes ingresar algun valor',
  'checkoutForm.termsCondDisclaimer':
    'El precio incluye la comisión del 2%. Al continuar estas aceptando los <1>términos y condiciones</1>.',
  'checkoutForm.flatFee': ' + cargo fijo {{fiatAsset}} {{flatFee}}',
  'checkoutForm.tooltip':
    'Precio basado en la cotización actual.<br/>La cotización final puede variar una vez<br/> que se acredite su pago. Una vez <br/>recibido su pago, se le enviará un <br/>correo electrónico para  que confirme <br/>la transacción con la tarifa final.',
  'checkoutForm.wantToBuy': 'Reciben',
  'checkoutForm.wantToSpend': 'Quiero enviar',
  'checkoutForm.wantToSell': 'Quiero enviar',
  'checkoutForm.wantToReceive': 'Reciben',
  'checkoutForm.exchangeToReceive': 'Recibir en la wallet',
  'checkoutForm.myEmail': 'Mi correo electrónico',
  'checkoutForm.weNeedYourEmail': 'Necesitamos tu correo para crear la orden',
  'checkoutForm.getStarted': 'Comenzar',
  'checkoutForm.whatIsYourWallet': '¿Cuál es tu otra wallet?',
  'checkoutForm.maxCharacters': 'La cantidad máxima de caracteres es de 50',
  'checkoutForm.alphaNumeric': 'Solo están permitidos caracteres alfanuméricos',
};
