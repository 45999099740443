import React, { useEffect } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import CurrencyFormat from 'react-currency-format';
import Skeleton from 'react-loading-skeleton';

import { useQuoteState, useQuoteDispatch, updateQuote } from '../../context/QuoteContext';

import { moneyMapping } from '../../constants/countryMap';

const QuoteInfo = React.memo(function QuoteInfo({
  cryptoValue = 0,
  fiatValue = 0,
  crypto,
  fiat,
  type,
  decimalScale = 8,
  pid,
}) {
  const { quoteInfo } = useQuoteState();
  const quoteDispatch = useQuoteDispatch();

  useEffect(() => {
    if (!crypto || !fiat || !quoteDispatch) return;
    const filter = {
      originAsset: crypto,
      destinationAsset: moneyMapping[fiat],
      type: type === 'Sell' ? 'Buy' : 'Sell',
      pid,
    };
    updateQuote(quoteDispatch, filter);
  }, [crypto, fiat, type, pid, quoteDispatch]);

  return (
    <Row className="quote-info">
      <Col xs={6} sm={6} className="ctf-value">
        {quoteInfo && quoteInfo.quote ? (
          <>
            1 {crypto} = {moneyMapping[fiat]}{' '}
            {quoteInfo && (
              <>
                <CurrencyFormat
                  value={quoteInfo.quote}
                  displayType="text"
                  thousandSeparator
                  fixedDecimalScale
                  decimalScale={2}
                />{' '}
              </>
            )}
          </>
        ) : (
          <Skeleton />
        )}
      </Col>
      <Col xs={6} sm={6} className="aprox-info">
        {type === 'Buy' ? (
          <>
            ≈{' '}
            <CurrencyFormat
              value={cryptoValue}
              displayType="text"
              decimalScale={decimalScale}
              thousandSeparator
              fixedDecimalScale
            />{' '}
            {crypto}
          </>
        ) : (
          <>
            ≈{' '}
            <CurrencyFormat value={fiatValue} displayType="text" thousandSeparator fixedDecimalScale decimalScale={2} />{' '}
            {moneyMapping[fiat]}
          </>
        )}{' '}
      </Col>
    </Row>
  );
});

export default QuoteInfo;
