import styled from 'styled-components';

export const OutlinedBox = styled.div`
  height: 47px;
  border: solid 2px ${props => props.theme['mercury-gray']};
  background-color: ${props => props.theme['white']};
  border-radius: 6px;
  box-sizing: border-box;
  padding: 0 0.75rem;
  margin: 0.5625rem 0;
  display: block;
  width: 100%;
  align-items: center;
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (display: flow-root) {
      width: 100%;
    }
  }

  input {
    border: 0;
    height: 39px;
    outline: none;
    font-family: 'Roboto';
    font-size: 1rem;
    width: 100%;
    background: transparent;
    &::placeholder {
      font-size: 1rem;
    }
  }
  @media (max-width: 480px) {
    margin-bottom: 1.4rem;
    .row {
      width: 100%;
    }
  }
`;
